.yotpo-stars{
  :global(.yotpo-bottomline){
    align-items: center;
  }
  :global(.yotpo.bottomLine a){
    color: #5D6469 !important;
    cursor: auto !important;
    padding-left: 10px !important;
    text-transform: lowercase !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 120% !important;
  }
  :global(.yotpo-icon){
    cursor: auto !important;
    font-size: 11.5px !important;
    line-height: 17px;
    color: #95C8D8 !important;
    padding-right: 4px !important;
  }
}
