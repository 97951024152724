.proven-dialog {
  h5 {
    @mixin rudH3Black;
  }

  :global(.MuiDialog-paperFullScreen) {
    border-radius: 0px;
    max-width: 100%;
    :global(.MuiDialogTitle-root) {
      padding: 20px 24px;
    }

    :global(.MuiDialogContent-root) {
      padding: 8px 24px 24px;
    }
  }
}
