:global(.congratulation-product .slick-list img) {
  border-radius: 16px;
  width: 100%;
  display: inline-block;
}

:global(.congratulation-product .slick-dots) {
  position: relative;
  bottom: 0;
  margin-top: 32px;

  li {
    width: 50px;
    height: 50px;
    margin: 0 8px;
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }

  @mixin smDown {
    display: flex !important;
  }
}

:global(.congratulation-product .slick-dots .slick-active img) {
  border: 3px solid #ffea79;
}
