.pricing {
  p {
    @mixin bodySm {};
    line-height: 1.5em;
    color: var(--color-gray);
    margin: 8px 0;
    font-size: 18px;
    line-height: 22px;
    @mixin md {
      font-size: 16px;
      line-height: 20px;
    }
  }
  .old {
    text-decoration: line-through;
    color: #918E8E;
    font-size: 14px;
    line-height: 16px;
  }

  .new {
    font-weight: bold;
    color: #7BC4AB;
    font-size: 22px;
    line-height: 24px;

    @mixin md {
      line-height: 24px;
    }
  }

  .no-slash-out {
    color: #918E8E;
    font-size: 15px;
    line-height: 24px;
    @mixin md {
      line-height: 22px;
    }
  }

  .alignCenter {
    display: flex;
    align-items: center;

    @mixin md {
      justify-content: left;
    }

    > span {
      margin-left: 8px;
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .textCenter {
    justify-content: center;
  }

  .freeShipping {
    @mixin titleCase2New {};
    color: var(--color-green);
  }
}
