.button {
  height: 50px;
  min-width: 260px;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(174.85deg, #FFB336 25.95%, #F59800 84.81%), #F6AE39;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  outline: none;
  color: #fff;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  &.green-border{
    border: 1px solid #7BC4AB;
    border-radius: 4px;
  }
  &.grey-border{
    border: 1px solid #5D6469 !important;
    border-radius: 4px;
  }
  &.text-underline{
    text-decoration-line: underline;
  }

  @mixin md {
    height: 55px;
    font-size: 18px;
  }


  /* States */

  &:hover:enabled {
    background: linear-gradient(176.44deg, #E9A433 25.95%, #E28C01 84.81%);

    &.custom-back {
      filter: brightness(90%);
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.loading {

  }


  /* Text content */

  .content {
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: row;
    align-items: center;
    height: 100%;

    .main {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        padding: 0 7px;
      }

      .text-sub {
        font-weight: lighter;
      }
    }


    .secondary {
      height: 100%;
      padding-left: 17px;
      padding-right: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255,190,85, .4);
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;

      @mixin md {
        font-size: 18px;
        line-height: 25px;
      }

      .text-crossed {
        opacity: 0.7;
        margin-right: 9px;
        padding: 0 1px;
        display: inline-block;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: -0.1em;
          right: -0.1em;
          top: 0.38em;
          bottom: 0.38em;
          background: linear-gradient(to left top, transparent 45.5%, currentColor 47.5%, currentColor 52.5%, transparent 54.5%);
          pointer-events: none;
        }
      }

      &.green {
        background: rgba(239, 249, 244, .3);
        //opacity: 0.3;
      }

      .text-second {

      }
    }
  }

  .content-loading {
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      margin-left: 10px;
    }
  }

  &.no-left-padding {
    .content .main .text{
      padding-left: 0;
    }
  }
  &.no-right-padding {
    .content .main .text{
      padding-right: 0;
    }
  }

  /* With secondary text */

  &.text-second {
    min-width: 300px;

    .content .main .text {
      padding: 0 10px;
    }
  }


  /* Sizes */

  &.sm {
    min-width: 200px;
    letter-spacing: 0.03em;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &.black-back {
      min-width: 120px;
    }
  }

  &.lg {
    font-size: 18px;
    line-height: 26px;

    @mixin md {
      font-size: 20px;
      line-height: 29px;
    }
  }

  /* Color styles */
  &.black-stroke {
    background: transparent;
    color: var(--color-black);
    border: 1px solid var(--color-black);

    &:hover:enabled {
      background: var(--color-black);
      color: var(--color-white) !important;
    }

    &[disabled] {
      color: #E6E6E6;
      border-color: #E6E6E6;
    }
  }

  &.white-stroke {
    background: transparent;
    color: var(--color-white);
    border: 1px solid var(--color-white);

    &:hover:enabled {
      background: transparent;
      color: var(--color-white);
    }

    &[disabled] {
      color: #E6E6E6;
      border-color: #E6E6E6;
      background: transparent;
    }
  }

  &.transparent-back {
    background: transparent;
    color: var(--color-black);

    &:hover:enabled {
      background: rgba(125,125,125,.1);
      color: var(--color-black);
    }

    &[disabled] {
      color: #E6E6E6;
      border-color: #E6E6E6;
    }
  }



  &.black-back {
    background: #323232;

    &:hover:enabled {
      background: #161616;
    }

    &[disabled] {
      background: #E6E6E6;
      color: #918E8E;
      opacity: 1;
    }
  }

  &.white-back {
    background: #fff;
    color: #F6AE39;

    &:hover:enabled {
      background: #f5f5f5;
    }
  }

  &.green-back {
    background: #7BC4AB;

    &:hover:enabled {
      background: #4E8872;
    }
  }
  &.highlighted-on-hover {

    &:hover:enabled {
      color: #7BC4AB;
      background: transparent;
      &.text-underline {
        text-decoration-color: #7BC4AB;
      }
      &.grey-border {
        border-color: #7BC4AB;
      }
      span,svg {
        color: #7BC4AB;
      }
      svg {
        fill: #7BC4AB;
      }
    }
  }

  &.green-gradient-back {
    background: linear-gradient(268.03deg, #7E93A4 17.79%, #A2BDB1 89.5%);

    &:hover:enabled {
      background: linear-gradient(268.03deg, #637684 17.79%, #81988E 89.5%);
    }
  }

  &.link-style {
    background: transparent;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    letter-spacing: normal;
    color: #918E8E;
    padding: 0;
    text-transform: none;
    min-width: unset;
    //text-decoration-line: underline;

    @mixin md {
      font-size: 18px;
      line-height: 22px;
    }

    &:hover:enabled {
      background: transparent;
    }
  }


  /* Font styles */

  &.text-transform-none {
    text-transform: none;
  }

  &.low-case-style {
    text-transform: none;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    letter-spacing: normal;

    @mixin md {
      font-size: 18px;
      line-height: 25px;
    }
  }
}
