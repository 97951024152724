.yotpo-stars {
  min-height: 20px;
  :global(.standalone-bottomline) {
    a {
      padding: 1px 0 1px 5px;
    }
  }

  &.no-stars-counter {
    :global(.yotpo-bottomline) {
      a {
        display: none;
      }
    }
  }

  &.two-lines {
    :global(.yotpo-bottomline) {
      flex-direction: column;
    }
  }

  &.yotpo-stars-loading {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  &.yotpo-stars-loaded {
    visibility: visible;
    opacity: 1;
  }
}
