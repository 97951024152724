.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    @mixin sm {
      margin: 0 auto;
      max-width: 600px;
      width: 100%;
    }
}

.tags-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 100%;
    margin: 45px 0;
    overflow: hidden;
}

.tags {
    display: flex;
    gap: 5px;
    width: 100%;
    position: static;
    flex-wrap: wrap;

    span{
        @mixin bodyMd;
        font-weight: 400;
        padding: 3px 6px;
        border-radius: 4px;
        white-space: nowrap;
        background: #256376;
        color: var(--color-white);

        @mixin md{
            font-size: 18px;
            line-height: 25px;
        }
    }
}

.subtitle {
    @mixin rudBody1Medium;
    margin-bottom: 10px;
}

.ingredients-wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.ingredients {
    display: flex;
    width: 100%;
    overflow: hidden;
    justify-content: space-around;
    gap: 10px;
    margin-bottom: 40px;
}

