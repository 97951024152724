.special-offer {
  text-align: center;
  color: var(--color-black);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 30px;
  height: 30px;

  b {
    font-weight: 600;
  }

  .orange {
    color: #F6AE3A;
  }

  .green {
    color: #7BC4AB;
  }

  .super {
    font-size: 9px;
    vertical-align: baseline;
    position: relative;
    top: -0.5em;
    @mixin desktop {
      top: -0.7em;
    }
  }

  .link {
    //color: #F6AE3A;
    color: #fff;
    text-decoration: underline;
    font-weight: 500;
  }

  @mixin desktop {
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.02em;
    height: 40px;
  }

  &.galentines {
    @mixin xs {
      font-size: 14px;
      line-height: 30px;
      letter-spacing: 0.02em;
      height: 30px;
    }
    @mixin sm {
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.02em;
      height: 40px;
    }
    @mixin md {
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.02em;
      height: 40px;
    }
    @mixin lg {
      font-size: 18px;
      line-height: 40px;
      letter-spacing: 0.02em;
      height: 40px;
    }
    @mixin xl {
      font-size: 18px;
      line-height: 40px;
      letter-spacing: 0.02em;
      height: 40px;
    }
  }
}
