.reviews-yotpo {

  @mixin paddingsLandingPage;
  background-color: white;

  .title {
    text-align: center;
    color: #323232;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 40px;
  }

  :global(.yotpo.yotpo-main-widget) {
    width: 100% !important;
    max-width: 1440px;
    margin: 0 auto !important;
    padding: 0 !important;

    @mixin smBranding {
      width: 100% !important;
    }

    @mixin mdBranding {
      background-color: yellow;
      width: 70% !important;
      margin: 0 auto !important;
      padding: 0 !important;
    }
  }

  /*************** HEADER RESUME start **************/

  /**
    others rated products (show sometimes)
 */
  :global(.promoted-products-box) {
    display: none !important;
  }

  /* yotpo logo */
  :global(.yotpo-label-container ) {
    display: none !important;
  }

  /**
    resume (stars resume and bars with star qty)
   */
  :global(.bottom-line-items-container-mobile) {

    /**
      show left side (stars resume and reviews qty)
     */
    :global(.bottom-line-items-wrapper) {
      position: absolute;
      left: calc(50% - 16px);
      transform: translate(-50%, 0);

      :global(.bottom-line-items) {
        padding-inline-end: 0px;
        padding-inline-start: 15px;
      }

      /**
        average score (big number)
       */
      :global(.avg-score) {
        font-family: 'Freight Big Comp Pro, serif'!important;
        font-size: 58px;
        font-weight: 900;
        line-height: 100%;
        color: #303C42;
        top: -34px;
        width: 100%;

        @mixin mdBranding {
          left: 47%;
        }
      }

      :global(.rating-stars-container) {
        :global(.yotpo-icon) {
          color: #FF9900 !important;
          font-size: 24px;
        }
      }

      :global(.reviews-qa-label) {
        width: 150px !important;
        font-family: 'Inter' !important;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 130%;
        text-align: center;
        justify-content: center;
      }
    }

    /**
      show right side (stars qty)
     */
    :global(.yotpo-star-distribution-wrapper) {
      display: none;
    }
  }

  :global(.bottom-line-only-container) {
    display: flex;
    flex-direction: column;
    align-items: center;
    @mixin mdBranding {
      max-width: 150px !important;
    }
  }

  :global(.reviews-qa-label) {
    width: 150px;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 130%;
    text-align: center;
  }

  :global(.reviews-qa-labels-container) {
    @mixin mdBranding {
      display: block;
    }
  }

  /*************** HEADER RESUME end ***************/


  /*************** for each REVIEW item start ***************/

  :global(.yotpo-review) {
    @mixin mdBranding {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }

  :global(.yotpo-main ) {
    margin-top: 32px !important;

    @mixin mdBranding {
      margin-top: 24px !important;
      margin-bottom: 24px !important;
    }
  }

  /**
    for each item review, show its footer
   */
  :global(.footer-actions) {
    display: none !important;
  }

  /**
    user's name
   */
  :global(.yotpo-user-name) {
    font-family: 'Inter' !important;
    color: #303C42 !important;
    font-size: 13px !important;
    line-height: 16px;
    font-weight: bold !important;

    @mixin mdBranding {
      font-size: 18px !important;
      line-height: 150%;
      font-weight: 600 !important;
    }
  }

  /**

   */
  :global(.yotpo-user-title){
    @mixin mdBranding {
      font-size: 18px !important;
      line-height: 150% !important;
    }
  }

  /**
    user review's title
   */
  :global(.content-title) {
    font-family: 'Inter' !important;
    font-size: 28px !important;
    color: #303C42;
    line-height: 120%;
    font-weight: 600 !important;
  }

  /**
    user review full description
   */
  :global(.content-review) {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    line-height: 150%;
    color: #6B757D !important;
  }

  :global(.yotpo-small .yotpo-header-actions) {
    display: inline-block !important;
  }
  /**
    user review date
   */
  :global(.yotpo-review-date) {
    font-size: 13px !important;
    line-height: 16px;
    color: #323232 !important;
  }

  /**
    dialog show when user post a review
   */
  :global(.bottom-line-items-container-desktop) {
    display: none!important;

    @mixin mdBranding {
      display: block!important;
    }
  }

  :global(.yotpo-hidden) {
    display: none!important;
  }

  /**
    users's icon (or first letter of his name)
   */
  :global(.yotpo-icon-profile) {
    background: #F0F7FA !important;
  }

  /**
    users's icon (or first letter of his name)
   */
  :global(.yotpo-user-letter) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    color: #95C8D8 !important;
  }

  /**
    yotpo start icon
   */
  :global(.yotpo-icon) {
    color: #95C8D8 !important;
  }

  :global(.rating-stars-container) {
    :global(.yotpo-icon) {
      color: #FF9900 !important;
      font-size: 24px;
    }
  }

  /**
      label show "Was This Review helpful?"
   */
  :global(.y-label.label-helpful) {
    font-family: 'Inter'!important;
    display: inline-block !important;
    font-size: 13px;
    font-weight: 500;
    line-height: 130%;
    color: #6B757D;
    /*help to know how to change text*/
    /*visibility: hidden;*/

    /**
      here is how to change the text of this label
     */
    /*help to know how to change text*/
    /*&:after {
      visibility: visible;
      content: "Was this helpful?";
    }*/
  }

  /**
    label show "Was This Review helpful?"
 */
  :global(.yotpo-helpful) {
    float: none!important;
  }

  /**
    thum up icon
   */
  :global(.yotpo-icon-thumbs-up) {
    color: #256376 !important;
  }

  /**
    additional info (like date)
   */
  :global(.footer-additional-actions) {
    display: none !important;
  }

  /*************** for each REVIEW item end ***************/

  /*************** NAVIGATION (tab) - start ***************/
  :global(.yotpo-nav) {
    li {
      &:first-child {
        :global(.yotpo-nav-wrapper) {
          &:after {
            /* this is how too change "REVIEWS" text (part 1)*/
            content: "Reviews";
          }

          & > span {
            /* this is how too change "REVIEWS" text (part 2)*/
            display: none;
          }
        }
      }

      &:last-of-type {
        :global(.yotpo-nav-wrapper) {
          &:after {
            /* this is how too change "QUESTIONS" text (part 1)*/
            content: "Questions";
          }

          & > span {
            /* this is how too change "QUESTIONS" text (part 2)*/
            display: none;
          }
        }
      }
    }
  }

  :global(.yotpo-modal-base) {
  }

  :global(.yotpo-reviews-header) {
    :global(.reviews-header) {
      /**
        review counter in the "sort" header
       */
      :global(.reviews-amount) {
        display: none;
      }
    }
  }

  /*************** NAVIGATION (tab) - end ***************/
}

/*****************************************************************
  FILTER MODAL (MOBILE) - start
  IN MOBILE when user click "More Filters" a modal is open
 *****************************************************************/
:global(.mobile-filters-modal) {
  :global(.yotpo-modal-dialog) {
    width: 100% !important;

    @mixin mdBranding {
      width: 70% !important;
    }

    :global(.mobile-filters-container) {
      padding-left: 5px;
    }

    :global(.mobile-filters-footer-btn) {
      width: 100%;

      @mixin smBranding {
        width: auto;
      }
      @mixin lgBranding {
        width: 660px;
      }
    }
  }
}

/************** FILTER MODAL (MOBILE) - end **************/




